.ProjectCard {
	height: 17.5rem;
	border: solid 0.15rem;
	border-radius: 1.5rem;
	background-color: #1d1e20;
	transition: background-color 200ms
		ease;
}

.ProjectCard:hover {
	cursor: pointer;
	background-color: #4f5257;
}

.ProjectCard img {
	width: 100%;
	height: 80%;
	border-radius: 1.4rem 1.4rem 0 0;
	border-bottom-width: 0;
	object-fit: contain;
	background: linear-gradient(
		135deg,
		#345 0%,
		#123 100%
	);
}

.ProjectCard .card-name {
	width: 100%;
	height: 15%;
	text-align: center;
	padding-top: 1rem;
	border-radius: 0 0 1.4rem 1.4rem;
}
