.Header {
	width: 100vw;
	height: 6vh;
	position: fixed;

	.HomeButton {
		position: absolute;
		top: 50%;
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		margin: 0px 0px 0px 1.5vh;
		padding: 0.5vh;
		background-color: #000000cc;
		transition: background-color 200ms ease;
		border-radius: 5vh;

		.Logo {
			float: left;
			width: 2.5vh;
			height: 2.5vh;
			margin-right: 1vh;
			border: 1px solid black;
			border-radius: 2.5vh;
			background: rgb(
				238,
				34,
				34
			);
			background: linear-gradient(
				100deg,
				rgba(238, 34, 34, 1) 0%,
				rgba(34, 34, 34, 1) 50%,
				rgba(17, 34, 170, 1)
					100%
			);
		}

		.Text {
			line-height: 2.5vh;
			vertical-align: middle;
			margin-right: 1vh;
		}
	}
	.HomeButton:hover {
		background-color: #777777cc;
	}
}
