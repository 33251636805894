@import url("https://fonts.googleapis.com/css2?family=Quintessential&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Almendra:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Eagle+Lake&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jim+Nightshade&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fondamento:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Balthazar&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Germania+One&display=swap");

.CharacterSheet {
	width: 80%;
	/* height: 500dvh; */
	background-image: url(../../public/parchment-texture.png);

	color: #221100dd;

	padding-left: 10%;
	padding-right: 10%;
	padding-top: 10vh;
	padding-bottom: 10vh;
}

.FontDemo {
	padding-bottom: 5vh;

	h1 {
		padding-bottom: 2vh;
	}

	p {
		padding-bottom: 2vh;
	}
}

.Quintessential {
	font-family: "Quintessential", serif;
	font-weight: 400;
	font-style: normal;

	font-size: x-large;

	h1 {
		font-size: xx-large;
	}

	p {
		line-height: 150%;
	}
}

.Almendra {
	font-family: "Almendra", serif;
	font-weight: 400;
	font-style: normal;

	font-size: x-large;

	h1 {
		font-size: xx-large;
	}

	p {
		line-height: 150%;
	}
}

.Eagle-Lake {
	font-family: "Eagle Lake", serif;
	font-weight: 400;
	font-style: normal;

	font-size: large;

	h1 {
		font-size: x-large;
	}

	p {
		line-height: 150%;
	}
}

.Jim-Nightshade {
	font-family: "Jim Nightshade",
		cursive;
	font-weight: 400;
	font-style: normal;

	font-size: x-large;

	h1 {
		font-size: xx-large;
	}

	p {
		line-height: 150%;
	}
}

.Fondamento {
	font-family: "Fondamento", cursive;
	font-weight: 400;
	font-style: normal;

	font-size: x-large;

	h1 {
		font-size: xx-large;
	}

	p {
		line-height: 150%;
	}
}

.Balthazar {
	font-family: "Balthazar", serif;
	font-weight: 400;
	font-style: normal;

	font-size: x-large;

	h1 {
		font-size: xx-large;
	}

	p {
		line-height: 150%;
	}
}

.Germania-One {
	font-family: "Germania One",
		system-ui;
	font-weight: 400;
	font-style: normal;

	font-size: x-large;

	h1 {
		font-size: xx-large;
	}

	p {
		line-height: 150%;
	}
}

/* @media screen and ((max-width: 1200px) or (max-height: 800px)) {
	@media screen and (orientation: portrait) {
		.CharacterSheet {
			background-color: #700;
		}
	}
	@media screen and (orientation: landscape) {
		.CharacterSheet {
			background-color: #070;
		}
	}
}

@media screen and ((min-width: 1201px) and (min-height: 801px)) {
	.CharacterSheet {
		background-color: #007;
	}
} */
