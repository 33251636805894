.HomePage {
	padding-top: 5vh;
	min-height: 100vh;
	background: rgb(238, 34, 34);
	background: linear-gradient(
		100deg,
		rgba(238, 34, 34, 1) 0%,
		rgba(34, 34, 34, 1) 50%,
		rgba(17, 34, 170, 1) 100%
	);
}

.card-container {
	max-width: 107.5rem;
	margin: auto;
	padding: 5rem;
	/* margin-top: 5rem;
	margin-bottom: 5rem; */
	display: grid;
	grid-template-columns: repeat(
		4,
		1fr
	);
	gap: 2.5rem;
}

@media screen and (max-width: 1900px) {
	.card-container {
		max-width: 80rem;
		grid-template-columns: repeat(
			3,
			1fr
		);
	}
}

@media screen and (max-width: 1450px) {
	.card-container {
		max-width: 52.5rem;
		grid-template-columns: repeat(
			2,
			1fr
		);
	}
}

@media screen and (max-width: 1000px) {
	.card-container {
		max-width: 25rem;
		grid-template-columns: repeat(
			1,
			1fr
		);
	}
}
