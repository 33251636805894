.ToolSelector {
	margin: 50px;
}

.toolContents {
	padding: 10px;
	background-color: rgb(59, 59, 211);
	border-radius: 10px;
}

.tool {
	width: fit-content;
	margin-right: 10px;
}

.toolList {
	margin-top: 100px;
	margin-bottom: 25px;
}

.divider {
	height: 10px;
	border-top: 2px solid white;
}