.CatchEmAll {
	text-align: left;
	min-height: 100vh;
	padding-bottom: 250px;
}

.input-container {
	position: fixed;
	left: 3rem;
}

.champs-in-rank {
	position: fixed;
	right: 3rem;
}

@media screen and (max-width: 1250px) {
	.input-container {
		position: static;
		width: 100vw;
		margin-top: 2rem;
		margin-bottom: 2rem;
	}

	.champs-in-rank {
		position: static;
		width: 100vw;
		margin-bottom: 4rem;
	}
}

tr:nth-child(odd) td {
	background-color: #ffffff0c;
}

.mastery-table {
	margin: auto;
	max-width: 70vw;
}

.mastery-table th {
	font-weight: 700;
}

.mastery-table th,
td {
	padding: 0.5rem;
}

.mastery-table
	.MasteryRow:nth-child(150) {
	border-bottom: thin solid;
}

.MasteryRow .last-played-time-amount {
	display: inline-block;
	text-align: right;
	padding-right: 10px;
	min-width: 3ch;
}

.MasteryRow .last-played-time-unit {
	color: #777;
}

.chest img {
	vertical-align: bottom;
	width: 30%;
	height: 30%;
}
.on img {
	filter: sepia(100%)
		hue-rotate(315deg)
		brightness(75%) saturate(1000%);
}

.off img {
	filter: sepia(100%)
		hue-rotate(175deg)
		brightness(25%) saturate(1000%);
}

.iron {
	color: #aaa4ab;
}

.bronze {
	color: #a67650;
}

.silver {
	color: #8ea5bb;
}

.gold {
	color: #f2c761;
}

.platinum {
	color: #3fedbb;
}

.diamond {
	color: #4e8df3;
}

.master {
	color: #c075d3;
}

.grandmaster {
	color: #d7575b;
}

.challenger {
	color: #9affff;
}
