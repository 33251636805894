.ActiveTimeBattleCard {
	margin-bottom: 20px;
	padding: 20px;
	background-color: #555;
	border-radius: 20px;
}

.infoLine {
	padding-bottom: 10px;

	span {
		padding-right: 30px;

		input {
			border-radius: 5px;
			border-color: transparent;
			color: white;
			background-color: #00000077;
		}
	}

	.progressLabel {
		float: right;
		text-align: right;
		padding-right: 5px;
		color: #ffffff77;
	}
}

.progressBar {
	height: 20px;
	background-color: #444;
	border-radius: 5px;
}

.progressBarFill {
	width: 0%;
	height: 100%;
	background-color: rgb(1, 197, 1);
	border-radius: 5px;
}
