.mobileTest {
	width: 100px;
	height: 100px;

	background-color: green;
}

.PointBuy {
	background-color: #3e4147;
	width: 100vw;
	height: 70vh;
	max-width: 800px;
	max-height: 1000px;
	margin: auto;
	display: grid;
	gap: 10px;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr;
}

.info {
	grid-area: "info";
}

.ability {
	background-color: blue;

	input {
		width: 30px;
	}
}
